import React, { Component } from 'react'

class SaleDeviceAF extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { jobData } = this.props
        return (
            <div id="spnPrint" size="A4" >
                <section>
                    <div>
                        <div className="main-prnthead">
                            <h2><i className="fa fa-yelp"></i>Sales Invoice</h2>
                            <span className="spn-inv">Invoice No. <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{jobData.INV_NUMBER}</span></span>
                        </div>
                        <div className="company-logo">Served By<br /><span style={{ fontSize: '23px' }}>Mobile Shop</span></div>
                    </div>
                    <div className="row main-info" >
                        <div className="lp-holder" >
                            <div className="left-pane">Bill To</div>
                            <div className="right-pane">{`${jobData.SALE_TO}`}</div>
                        </div>
                        <div className="rp-holder">
                            <div className="left-pane">Sales Date</div>
                            <div className="right-pane">{new Date().toLocaleDateString()}</div>
                        </div>
                        <div className="rp-holder">
                            <div className="left-pane">Print Date</div>
                            <div className="right-pane">{new Date().toLocaleDateString()}</div>
                        </div>
                    </div >
                    <div className="clearfix"></div>
                    <div className="col-md-12" style={{ padding: '27px', paddingBottom: '10px', paddingTop: '10px' }}>
                        < table className="tbl-details" align="center" >
                            <tr>
                                <th>Sno</th>
                                <th>Product Name</th>
                                <th>Description</th>
                                {jobData.FOR === 'Device' ?
                                    <th>IMEI Number</th>
                                    :
                                    null
                                }
                                <th>Qty</th>
                                {jobData.FOR === 'Items' ?
                                    <th>Price/Item</th>
                                    :
                                    null
                                }
                                <th>Discount</th>
                                <th>Amount</th>
                            </tr>
                            {jobData.ITEM_LIST && jobData.ITEM_LIST.map((ele, index) => {
                                let PName = jobData.FOR === 'Items' ? ele.ItemName.value : `${ele.ItemName.ItemBrand} ${ele.ItemName.display}`
                                return (
                                    <tr key={index} >
                                        <td className="text-center">{index + 1}</td>
                                        <td>{PName}</td>
                                        <td>{ele.ItemDesc}</td>
                                        {jobData.FOR === 'Device' ?
                                            <td>{ele.ItemName.id}</td>
                                            :
                                            null
                                        }
                                        <td>{ele.ItemQuantity}</td>
                                        {jobData.FOR === 'Items' ?
                                            <td>£ {ele.ItemRate}</td>
                                            :
                                            null
                                        }
                                        <td>{ele.ItemDisc || 0}</td>
                                        <td>£ {ele.ItemAmount}</td>
                                    </tr>
                                )
                            })}

                        </table >
                    </div >
                    <div className="col-md-12" style={{ padding: '27px', paddingTop: '0px' }}>
                        <div style={{ display: 'inline-block', width: '65%' }}>
                            {/* <p>Payment Method: <strong>{jobData.PC_REP_REM_AM_TYPE}</strong></p> */}
                            <p style={{ fontSize: '11px', textAlign: 'justify' }}>
                                Thanks for visiting PLEASE KEEP YOUR RECEIPT as your PROOF OF PURCHASE (Customer Copy), Purchasing the
                                goods from us means that you agree and accept these <strong>terms & conditions</strong>.</p>
                        </div>
                        <table className="tbl-amount" align="right">
                            <tr>
                                <td className="text-right">Grand Total:</td>
                                <td>£ {jobData.GRAND_TOTAL}</td>
                            </tr>
                        </table>
                        <p>&nbsp;</p>
                        <p style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'justify', whiteSpace: 'pre-wrap' }}>{localStorage.getItem('SP_PRINT_CONTENT')}</p>
                    </div>
                    <div className="clearfix"></div>
                </section >
            </div >
        );
    }
}

export default SaleDeviceAF;