import React, { Component } from "react"
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap"
import { Home, ExitToApp, ShoppingCart, Build, PersonAddAlt, ManageAccounts, Receipt, Inventory, Assessment, PointOfSale, Summarize, GavelSharp } from "@mui/icons-material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSideBar: true,
            PC_USR_ROLE_ID: localStorage.getItem('PC_USR_ROLE_ID'),
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
            tabName: "dashboard"
        }
    }

    componentDidMount() {
        this.setState({ tabName: window.location.pathname })
    }

    handleSwitchTab(tabName) {
        switch (tabName) {
            case "profit-loss":
            case "account-balance":
            case "inventory-report":
            case "get-item-rates":
                this.setState({ tabName, showSideBar: false })
                break;
            default:
                this.setState({ tabName })
                break;
        }
    }

    handleOpensideMenu(bool) {
        this.setState({ showSideBar: bool })
    }

    handleSignOut() {
        localStorage.removeItem('PC_GRP_ATT');
        localStorage.removeItem('PC_GRP_ID');
        localStorage.removeItem('PC_USR_ROLE_ID');
        localStorage.removeItem('PC_USR_EMAIL');
        localStorage.removeItem('PC_USR_NAME');
        localStorage.removeItem('PC_USR_ID');
        localStorage.removeItem('PC_USR_TOKEN');
        localStorage.removeItem('OTHER_DATA');
        window.location.assign('/admin')
    }

    render() {
        let { tabName, PC_USR_ROLE_ID, PC_GRP_ID } = this.state
        return (
            <div style={{ background: '#f9f9f9', height: '100%', overflowY: 'hidden' }} >
                <div style={{ height: "100%" }} >
                    <div className="sidebar" style={{ display: this.state.showSideBar ? "block" : "none" }} >
                        <Navbar>
                            <Navbar.Brand style={{ color: '#fff', padding: "5px" }} >Phone Clinic</Navbar.Brand>
                            <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text style={{ color: '#111827' }} >
                                    <IconButton style={{ marginRight: "15px", color: "#ffff" }} aria-label="Exit" onClick={this.handleOpensideMenu.bind(this, false)} >
                                        <CloseIcon />
                                    </IconButton>
                                </Navbar.Text>
                            </Navbar.Collapse>
                        </Navbar>
                        <Link style={{ textDecoration: 'none' }} to={'/admin/dashboard'}><p className={tabName === "dashboard" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "dashboard")}><Home /> Dashboard</p></Link>
                        {(PC_USR_ROLE_ID === "2" || PC_USR_ROLE_ID === "1") && PC_GRP_ID === '2' ?
                            <div>
                                {/* <Link style={{ textDecoration: 'none' }} to={'/admin/user'}><p className={tabName === "user" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "user")}><PersonAddAlt /> Users</p></Link> */}
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <ShoppingCart /> Setup
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/job-services'}><p className={tabName === "job-services" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "job-services")}>Job Services</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/vendor-setup'}><p className={tabName === "vendor-setup" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "vendor-setup")}>Vendor Setup</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <AccountTreeIcon /> Account
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/shop-payments'}><p className={tabName === "shop-payments" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "shop-payments")} >Shop Payments</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <GavelSharp /> Repair
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/job'}><p className={tabName === "job" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "job")}>Job</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <Inventory /> Inventory
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/devices'}><p className={tabName === "devices" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "devices")}>Devices</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/items'}><p className={tabName === "items" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "items")}>Item</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Sale"
                                        id="Sale"
                                    >
                                        <PointOfSale /> Sale
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/sale-invoice'}><p className={tabName === "sale-invoice" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "sale-invoice")}>Item</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/sale-device'}><p className={tabName === "sale-device" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "sale-device")}>Device</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="SalenPurchase"
                                        id="SalenPurchase"
                                    >
                                        <ManageAccounts /> Purchase
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/purchase-device'}><p className={tabName === "purchase-device" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "purchase-device")}>Purchase Device</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Report"
                                        id="Report"
                                    >
                                        <Summarize /> Report
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        {/* <Link style={{ textDecoration: 'none' }} to={'/admin/sale-report'}><p className={tabName === "sale-report" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "sale-report")}>Sale</p></Link> */}
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/get-item-rates'}><p className={tabName === "get-item-rates" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "get-item-rates")}>Get Item Rate</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/inventory-report'}><p className={tabName === "inventory-report" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "inventory-report")}>Inventory Available</p></Link>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Others"
                                        id="Others"
                                    >
                                        <Build /> Others
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/refund-device'}><p className={tabName === "refund-device" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "refund-device")} >Refund Device</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            :
                            ''
                        }
                        {PC_USR_ROLE_ID === "2" && PC_GRP_ID === '4' ?
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Finance"
                                        id="Finance"
                                    >
                                        <ManageAccounts /> Finance
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/account-payments'}><p className={tabName === "account-payments" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "account-payments")} >Account Payments</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Report"
                                        id="Report"
                                    >
                                        <Assessment /> Report
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/report/profit-loss'}><p className={tabName === "profit-loss" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "profit-loss")} >profit & Loss</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/report/account-balance'}><p className={tabName === "account-balance" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "account-balance")} >Account Balance</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            :
                            ""
                        }
                        {PC_USR_ROLE_ID === "1" && PC_GRP_ID === '1' ?
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Setup"
                                        id="Setup"
                                    >
                                        <ShoppingCart /> Setup
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        {/* <Link className="sidenav-tabs" to={'/admin/dashboard'} ><div onClick={this.handleSwitchTab.bind(this, "/dashboard")} className={tabPath === "/dashboard" ? "tab-active p-1" : "p-1"} >Dashboard</div></Link> */}
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/item-category'}><p className={tabName === "item-category" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "item-category")} >Item Category</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/brand-setup'}><p className={tabName === "brand-setup" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "brand-setup")} >Brand Setup</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-inventory'}><p className={tabName === "system-inventory" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-inventory")} >Inventory</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-devices'}><p className={tabName === "system-devices" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-devices")} >Devices</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-services'}><p className={tabName === "system-services" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-services")} >Services</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-users'}><p className={tabName === "system-users" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-users")} >Users</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-shop'}><p className={tabName === "system-shop" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-shop")} >Shop</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-pay-account'}><p className={tabName === "system-pay-account" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-pay-account")} >Pay Account</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/system-warehouse'}><p className={tabName === "system-warehouse" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "system-warehouse")} >Warehouse</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Finance"
                                        id="Finance"
                                    >
                                        <ManageAccounts /> Finance
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/weekly-sale'}><p className={tabName === "weekly-sale" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "weekly-sale")} >Weekly Sale</p></Link>
                                        {/* <Link style={{ textDecoration: 'none' }} to={'/admin/weekly-delivery'}><p className={tabName === "weekly-delivery" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "weekly-delivery")} >Weekly Delivery</p></Link> */}
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/shop-payments'}><p className={tabName === "shop-payments" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "shop-payments")} >Shop Payments</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/account-payments'}><p className={tabName === "account-payments" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "account-payments")} >Account Payments</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Report"
                                        id="Report"
                                    >
                                        <Assessment /> Report
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/report/profit-loss'}><p className={tabName === "profit-loss" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "profit-loss")} >profit & Loss</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/report/account-balance'}><p className={tabName === "account-balance" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "account-balance")} >Account Balance</p></Link>
                                        {/* <Link style={{ textDecoration: 'none' }} to={'/report/warehouse-balance'}><p className={tabName === "warehouse-balance" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "warehouse-balance")}>Warehouse Balance</p></Link> */}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            :
                            ""
                        }
                        {PC_USR_ROLE_ID === "2" && PC_GRP_ID === '3' ?
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Setup"
                                        id="Setup"
                                    >
                                        <ShoppingCart /> Setup
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/vendor-setup'}><p className={tabName === "vendor-setup" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "vendor-setup")}>Vendor Setup</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <Inventory /> Inventory
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/warehouse-device'}><p className={tabName === "warehouse-device" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "warehouse-device")}>Devices</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/warehouse-inventory'}><p className={tabName === "warehouse-inventory" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "warehouse-inventory")}>Items</p></Link>
                                        {/* <Link style={{ textDecoration: 'none' }} to={'/admin/device-repair-items'}><p className={tabName === "device-repair-items" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "device-repair-items")}>Device Repair</p></Link> */}
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Sale"
                                        id="Sale"
                                    >
                                        <PointOfSale /> Sale
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/sale-device-wh'}><p className={tabName === "sale-device-wh" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "sale-device-wh")}>Device</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/sale-item-wh'}><p className={tabName === "sale-item-wh" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "sale-item-wh")}>Item</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Purchase"
                                        id="Purchase"
                                    >
                                        <ManageAccounts /> Purchase
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/purchase-device-wh'}><p className={tabName === "purchase-device-wh" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "purchase-device-wh")}>Device</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: '#333a4b', color: 'white' }} >
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Build /> Others
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px 5px 5px' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/refund-device-wh'}><p className={tabName === "refund-device-wh" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "refund-device-wh")} >Refund Device</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            :
                            ""
                        }

                        {PC_USR_ROLE_ID === "3" && PC_GRP_ID === '2' ?
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <GavelSharp /> Repair
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/job'}><p className={tabName === "job" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "job")}>Job</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Sale"
                                        id="Sale"
                                    >
                                        <PointOfSale /> Sale
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/sale-invoice'}><p className={tabName === "sale-invoice" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "sale-invoice")}>Item</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="sideTab"
                                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                        aria-controls="Inventory"
                                        id="Inventory"
                                    >
                                        <Inventory /> Inventory
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0px', background: '#333a4b', color: 'white' }} >
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/devices'}><p className={tabName === "devices" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "devices")}>Devices</p></Link>
                                        <Link style={{ textDecoration: 'none' }} to={'/admin/items'}><p className={tabName === "items" ? "active" : ""} onClick={this.handleSwitchTab.bind(this, "items")}>Item</p></Link>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            :
                            ""
                        }
                    </div>
                    <div className="content" style={{ marginLeft: this.state.showSideBar ? "200px" : "0px" }} >
                        <Navbar style={{ background: '#ffff' }} >
                            {/* <Navbar.Brand style={{ color: 'grey', padding: "5px" }} ></Navbar.Brand> */}
                            {!this.state.showSideBar ?
                                <Navbar.Collapse className="justify-content-start">
                                    <Navbar.Text style={{ color: '#111827' }} >
                                        <IconButton style={{ marginRight: "15px", color: "#416bc7" }} aria-label="Open" onClick={this.handleOpensideMenu.bind(this, true)} >
                                            <MenuOpenIcon />
                                        </IconButton>
                                    </Navbar.Text>
                                </Navbar.Collapse>
                                :
                                null
                            }
                            <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text style={{ color: '#111827' }} >
                                    <IconButton style={{ marginRight: "15px", color: "#416bc7" }} aria-label="Exit" onClick={this.handleSignOut.bind(this)} >
                                        <ExitToApp />
                                    </IconButton>
                                </Navbar.Text>
                            </Navbar.Collapse>
                        </Navbar>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminLayout;